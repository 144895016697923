@import '~antd/dist/antd.less';
@tailwind base;
@tailwind components;
@tailwind utilities;


@primary-client: #FF5D03;

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../assets/fonts/Montserrat-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url(../assets/fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: bold;
}

  
body {
  margin: 0;
  font-family: -apple-system, 'Montserrat',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  
}

h1, h2, h3, h4, h5, h6 {
  color: #0D2252;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #DD664C;
  border: 0px none #C8C8C8;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #DD664C;
}
::-webkit-scrollbar-thumb:active {
  background: #DD664C;
}
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0);
  border: 0px none rgba(0,0,0,0);
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #C8C8C8;
}
::-webkit-scrollbar-track:active {
  background: rgba(0,0,0,0);
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.ant-carousel .slick-dots li button {
  background: #0D2252 !important;
  opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #0D2252 !important;
}

.ant-card-meta-description {
  color: #0D2252 !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: 2em !important;
  font-weight: bold !important;
  color: #1f51c4 !important;
}

.ant-steps-item-active {
  .ant-steps-item-icon {
    border-color: @primary-client !important;
    background-color: @primary-client !important;
    color: white !important;
  }

  .ant-steps-icon {
    color: white !important;
  }
} 

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #FF5D03 !important;
}

.ant-steps-item-title {
  font-size: 12px !important;
  font-weight: normal;
}

.ant-steps-item-process {
  .ant-steps-item-title {
    color: @primary-client !important;
    font-weight: bolder;
  }

  .ant-steps-item-icon {
    border-color: @primary-client !important;
    color: @primary-client !important;
  }
}

.ant-steps-item-finish {
  .ant-steps-item-icon {
    border-color: @primary-client !important;
    color: @primary-client !important;
  }

  .ant-steps-icon {
    color: @primary-client !important;
  }

  .ant-steps-item-title {
    color: @primary-client !important;
    font-weight: bolder;
  }
} 

.ant-steps-item-wait {
  .ant-steps-item-title {
    color: #B6B8B8 !important;
    font-weight: bolder;
  }

  .ant-steps-item-icon {
    border-color: #B6B8B8 !important;
    color: #B6B8B8 !important;
  }
  
  .ant-steps-item-tail::after {
    background: #B6B8B8 !important;
  }
}

.ant-input-group-addon {
  color: @primary-client !important;
}

.react-rainbow-datepicker input {
  border-radius: 0.375rem !important;
  border: 1px solid #D0D0D0;
}

.ant-collapse-header {
  padding: 0 !important;
}


#preferred_date_time_modal {
  background: fade(black, 45%);
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-left: 0;
  padding-right: 0;
}
@primary-color: #0D2252;@text-color: #0D2252;@link-color: #1DA57A;@layout-header-background: #ffffff;@label-color: #0D2252;@form-item-label-font-size: @font-size-base * 1.25;@btn-font-weight: 600;@input-border-color: #D0D0D0;@control-padding-horizontal: 20px;@height-lg: 44px;@select-single-item-height-lg: 44px;@divider-color: #C4C4C4;@divider-text-padding: 1.5em;@border-radius-base: 0.375rem;@btn-border-width: 0;@zindex-notification: 10001;@steps-background: white;@process-tail-color: #FF5D03;@border-width-base: 2px;